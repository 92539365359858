<template src="./index.html" />

<script>
import {
  BButton, BCard, BCardBody, BCardHeader, BCardTitle, BCol, BRow, BContainer, BImg,
  BFormInput, BFormDatepicker, BFormGroup, BForm, BFormFile, BTab, BTabs, BOverlay, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { getUser } from '@/auth/utils'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import { HAS_LEARN_PARTY } from '@/const/selection'

export default {
  name: 'Index',
  directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BButton,
    BContainer,
    BImg,
    BFormInput,
    BFormDatepicker,
    BFormGroup,
    BForm,
    vSelect,
    BFormFile,
    BTab,
    BTabs,
    BOverlay,
    VueGoodTable,
    BFormSelect,
    BPagination,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      studentInfo: {
        firstName: '',
        lastName: '',
        birthDay: '',
        gender: '',
        email: '',
        mobile: '',
        address: '',
        identifyCode: '',
        homeTown: '',
        dateDangcongsanGroup: '',
        studentSocialInsurance: '',
        hasLearnParty: '',
        dateDangcongsanGroupChinhthuc: '',
        dateYouthGroup: '',
        birthPlace: '',
        urlImg: '',
      },
      mainProps: {
        fluidGrow: true,
        height: 200,
      },
      avatar: [],
      ulrAvatar: '',
      currentPage: 1,
      itemsPerPage: 20,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      scholarshipColumns: [
        {
          label: 'Khóa học',
          field: 'course',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập khóa học',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semester',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập học kỳ',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tên học bổng',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên học bổng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      awardColumns: [
        {
          label: 'Tên khen thưởng',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên khen thưởng',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      disciplineColumns: [
        {
          label: 'Tên kỷ luật',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tên kỷ luật',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày kỷ luật',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày kỷ luật',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      policyColumns: [
        {
          label: 'Đối tượng trợ cấp',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập đối tượng trợ cấp',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      allowanceColumns: [
        {
          label: 'Trợ cấp sư phạm',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập trợ cấp sư phạm',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Ngày nhận',
          field: 'createdAt',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập ngày nhận',
          },
          sortable: true,
          thClass: 'text-center',
        },
      ],
      gendersSelect: [],
    }
  },

  async created() {
    // init data FROM API
    await this.getGenders({})
    console.log(this.genders)
    this.gendersSelect = this.genders.map(item => ({ value: item.id, label: item.name }))
    await this.getInitData()
  },

  computed: {
    // calculate data from init data
    ...mapGetters({
      infoStudent: 'student/infoStudent',
      studentInfo1: 'curriculumVitae/studentInfo',
      curriculumVitaeLearnings: 'curriculumVitae/curriculumVitaeLearnings',
      curriculumVitaeScholarships: 'curriculumVitae/curriculumVitaeScholarships',
      curriculumVitaeAwards: 'curriculumVitae/curriculumVitaeAwards',
      curriculumVitaeDisciplines: 'curriculumVitae/curriculumVitaeDisciplines',
      curriculumVitaePolicies: 'curriculumVitae/curriculumVitaePolicies',
      curriculumVitaeAllowances: 'curriculumVitae/curriculumVitaeAllowances',
      genders: 'dropdown/genders',
    }),
    hasLearnPartySelect() {
      return HAS_LEARN_PARTY
    },
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    classId() {
      const { programmes, portalProgrammeId } = this.user
      if (!portalProgrammeId || programmes.length === 0) return null
      const found = programmes.find(element => element.id === portalProgrammeId)
      return found ? found.classId : null
    },
  },
  methods: {
    // handle even
    ...mapActions({
      getInfoStudent: 'student/getInfoStudent',
      updateInfoStudent: 'student/updateInfoStudent',
      uploadAvatar: 'student/uploadAvatar',
      getStudentInfoNew: 'curriculumVitae/getStudentInfo',
      getGenders: 'dropdown/getGenders',
    }),

    async onSubmit(event) {
      event.preventDefault()
      const res = await this.updateInfoStudent(this.studentInfo)

      if (res.isSuccessful) {
        this.showToast('Cập nhật thành công', 'CheckIcon', 'success')
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
    },

    async uploadFile(e) {
      const formData = new FormData()
      const { files } = e.target
      if (files.length === 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File đang bị trống!',
            icon: 'InfoIcon',
            variant: 'warning',
          },
        })
        return
      }

      files.forEach(file => formData.append('files', file))
      const response = await this.uploadAvatar(formData)
      if (response.isSuccessful) {
        this.showToast('Cập nhật avatar thành công', 'CheckIcon', 'success')
        await this.getInitData()
      } else {
        this.showToast('Có lỗi xẩy ra', 'XCircleIcon', 'danger')
      }
      this.$refs.file.reset()
    },
    async getInitData() {
      this.isLoading = true
      await this.getStudentInfoNew({
        studentId: this.user.studentId,
        classId: this.classId,
      })
      await this.getInfoStudent(this.user.studentId)
      this.studentInfo = { ...this.studentInfo, ...this.infoStudent }
      this.ulrAvatar = this.studentInfo.pathMinio
      this.isLoading = false
    },

    showToast(title, icon, variant, text) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
          text,
        },
      })
    },

  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
